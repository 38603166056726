import React from 'react'
import { graphql } from 'gatsby'
import SimpleReactLightbox from 'simple-react-lightbox'
import SEO from 'components/SEO'
import PostContent from 'components/Research/Post'

const ResearchPost = ({ data, location }) => {
  const { markdownRemark } = data
  return (
    <>
      <SEO title={data.markdownRemark.frontmatter.title} description={data.markdownRemark.excerpt} />
      <SimpleReactLightbox>{<PostContent data={markdownRemark} pathname={location.pathname} />}</SimpleReactLightbox>
    </>
  )
}

export default ResearchPost

export const query = graphql`
  query ResearchPost($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        images {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
      }
      html
      excerpt
    }
  }
`
